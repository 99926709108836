import { houseSearchType } from '@routerConfig'

// 是否服务端运行
export const IS_SERVER = typeof window === 'undefined'

// 页面主题样式
export const THEME_CLASS = {
  default: '',
  commercial: 'theme-commercial',
  rural: 'theme-rural'
}

// 页面响应式尺寸
export const PAGE_BREAKPOINT = {
  sm: 640,
  md: 768,
  lg: 1024
}

// 社交平台url
export const SOCIAL_URL = {
  instagram: 'https://www.instagram.com/oneroofnz/',
  facebook: 'https://www.facebook.com/oneroofpropertynz',
  linkedin: 'https://www.linkedin.com/company/oneroofnz'
}

// app 下载链接
export const APP_DOWNLOAD_URL = {
  ios: 'https://apps.apple.com/nz/app/oneroof/id1336312690',
  android: 'https://play.google.com/store/apps/details?id=com.nzme.oneroof&hl=en'
}

// hubpage 模块类型
export const HUBPAGE_SECTION_TYPE = {
  heroImage: 'hero-image',
  topic: 'topic',
  findProperties: 'find-properties',
  contentBox: 'content-box',
  ad: 'ad',
  googleAd: 'googleAd',
  exploreByType: 'explore-by-type',
  exploreByRegion: 'explore-by-region',
  featuredDev: 'featured-dev',
  featuredBuilder: 'featured-builder',
  newsHero: 'news-hero',
  newsBlock: 'news-block',
  nbs: 'nbs',
  orGuide: 'or-guide',
  homeServices: 'home-services',
  formBuilder: 'form-builder',
  circleNav: 'circle-nav',
  findYourPerfectProperties: 'find-your-perfect-properties',
  hottestListing: 'hottest-listing',
  textArea: 'text-area'
}

// hubpage 类型
export const HUBPAGE_TYPE = {
  news: 'news',
  advice: 'advice',
  h: 'h',
  insights: 'insights'
}

// hubpage newsHero类型
export const NEWS_HERO_TYPE = {
  hero: 'hero',
  medium: 'medium',
  small: 'small',
  ads: 'ads',
  googleAds: 'googleAds'
}

// 房源bedrooms
export const HOUSE_BEDROOMS = [
  { value: null, label: 'Any' },
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5+' }
]

// 房源bathrooms
export const HOUSE_BATHROOMS = [
  { value: null, label: 'Any' },
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5+' }
]

// 房源设施文案
export const HOUSE_FACILITY_LABEL = {
  '0': 'S',
  '1': 1,
  '2': 2,
  '3': 3,
  '4': 4,
  '5': '5+'
}

// landarea label
export const LANDAREA_LABEL = [
  { value: null, label: 'Any' },
  { value: '0-50', label: '<50m<sup>2</sup>' },
  { value: '50-100', label: '50 - 100m<sup>2</sup>' },
  { value: '100-150', label: '100 - 150m<sup>2</sup>' },
  { value: '150-300', label: '150 - 300m<sup>2</sup>' },
  { value: '300-500', label: '300 - 500m<sup>2</sup>' },
  { value: '500-750', label: '500 - 750m<sup>2</sup>' },
  { value: '750-1000', label: '750 - 1,000m<sup>2</sup>' },
  { value: '1000-2000', label: '1,000 - 2,000m<sup>2</sup>' },
  { value: '2000-5000', label: '2,000 - 5,000m<sup>2</sup>' },
  { value: '5000-7500', label: '5,000 - 7,500m<sup>2</sup>' },
  { value: '7500-10000', label: '7,500 - 10,000m<sup>2</sup>/1ha' },
  { value: '10000-100000', label: '1 - 10ha' },
  { value: '100000-200000', label: '10 - 20ha' },
  { value: '200000-300000', label: '20 - 30ha' },
  { value: '300000-400000', label: '30 - 40ha' },
  { value: '400000-500000', label: '40 - 50ha' },
  { value: '500000+', label: '50ha+' }
]

// 房源类型id
export const HOUSE_TYPE_ID = {
  'residential': 1,
  'rural': 3,
  'agent-rental': 5,
  'personal-rental': -1,
  'commercial-property-for-sale': 2,
  'commercial-property-for-lease': 6,
  'businesses-for-sale': 4,
  'buy': 1,
  'rent': 5,
  'leased': 6,
  'sold': -99,
  'new-home': 1,
  'estimate': -100,
  'new-homes': 1,
  'houses-for-sale': 1,
  'houses-for-rent': 5,
  'need-search': -101,
  'rv': -104,
  'village': 7
}

// 中介类型id
export const AGENT_TYPE_ID = {
  residential: 1,
  rural: 3,
  commercial: 2,
  business: 4
}

// 中介公司类型id
export const OFFICE_TYPE_ID = {
  propertyManagment: 5,
  residential: 1
}

// 新闻详情body卡片类型
export const NEWS_BODY_TAG_TYPE = {
  text: 'text-tag',
  ad: 'ad-tag',
  nbs: 'nbs-tag',
  office: 'office-tag',
  agent: 'agent-tag',
  house: 'house-tag',
  sold: 'sold-tag',
  estimate: 'estimate-tag',
  carousel: 'carousel-tag',
  marketinsight: 'marketinsight-tag',
  priceInteractive: 'property-report-tag',
  searchLink: 'search-link-tag'
}

// commercial 类型
export const COMMERCIAL_TYPE = {
  sale: 'commercial-property-for-sale',
  lease: 'commercial-property-for-lease',
  business: 'businesses-for-sale'
}

// explore all ....(主要用于首页)
export const EXPLORE_TYPE = {
  commercialForSale: 'commercial-for-sale',
  commercialForLease: 'commercial-for-lease',
  businessesForSale: 'businesses-for-sale',
  rural: 'rural',
  newhomes: 'newhomes',
  apartmentsTerraces: 'apartments-terraces',
  houseLand: 'house-land',
  builders: 'builders'
}

// auckland centre
export const AUCKLAND_CENTRE = { lat: -36.846397, lng: 174.766481, zoom: 12 }

// agent source
export const AGENT_SOURCE = {
  homepage: 'find-agent-homepage',
  searchResult: 'find-agent-search-result',
  suburbProfile: 'suburb-profile',
  estimateDetail: 'estimate-detail'
}

// 中介类型
export const AGENT_TYPE = {
  sale: 'residential-sales',
  commercial: 'commercial-agent',
  business: 'business-agent',
  rural: 'rural-agent',
  propertyManagment: 'property-managment',
  agency: 'agency'
}

// new zealand
export const NEW_ZEALAND = {
  label: 'All New Zealand',
  shortLabel: 'New Zealand',
  id: 1,
  level: 'region'
}

// export const AREA_UNIT = [
//   { label: 'm<sup>2</sup>', value: 1 },
//   { label: 'ha', value: 2 },
//   { label: 'ac', value: 3 }
// ]

// 房源详情计算器还款选项
export const CALCULATOR_REPAYMENT = [
  { value: 2, label: 'Monthly Repayments', alias: 'Monthly' },
  { value: 3, label: 'Fortnightly Repayments', alias: 'Fortnightly' },
  { value: 4, label: 'Weekly Repayments', alias: 'Weekly' }
]

// 房源详情计算器贷款期限选项
export const CALCULATOR_LOAN_TERM = [
  { label: '6 months', value: 0.5 },
  { label: '12 months', value: 1 },
  { label: '18 months', value: 1.5 },
  { label: '2 years', value: 2 },
  { label: '3 years', value: 3 },
  { label: '4 years', value: 4 },
  { label: '5 years', value: 5 },
  { label: '10 years', value: 10 },
  { label: '15 years', value: 15 },
  { label: '20 years', value: 20 },
  { label: '25 years', value: 25 },
  { label: '30 years', value: 30 }
]

// 房源详情计算器利率选项
export const CALCULATOR_INTEREST_RATE = [
  { label: '8.07% - Floating', value: 8.07 },
  { label: '7.19% - 6 Months', value: 7.19 },
  { label: '6.63% - 1 Year', value: 6.63 },
  { label: '6.19% - 18 Months', value: 6.19 },
  { label: '6.23% - 2 Years', value: 6.23 },
  { label: '6.13% - 3 Years', value: 6.13 },
]

// 房源列表排序
export const ORDER_HOUSE = [
  { value: null, label: 'Featured' },
  { value: 0, label: 'Latest' },
  { value: 2, label: 'Lowest price' },
  { value: 1, label: 'Highest price' },
  { value: 8, label: 'Earliest open home' }
]

// sold列表排序
export const ORDER_SOLD = [
  { value: null, label: 'Featured' },
  { value: 0, label: 'Sold date' },
  { value: 2, label: 'Lowest price' },
  { value: 1, label: 'Highest price' }
]

// commercial列表排序
export const ORDER_COMMERCIAL = [
  { value: null, label: 'Featured' },
  { value: 0, label: 'Latest' },
  { value: 2, label: 'Lowest price' },
  { value: 1, label: 'Highest price' },
  { value: 11, label: 'Smallest floor area' },
  { value: 12, label: 'Largest floor area' }
]

// business列表排序
export const ORDER_BUSINESSES = [
  { value: null, label: 'Featured' },
  { value: 0, label: 'Latest' },
  { value: 2, label: 'Lowest price' },
  { value: 1, label: 'Highest price' }
]

// agent列表排序
export const ORDER_AGENT = [
  { value: null, label: 'Featured' },
  { value: 6, label: 'Latest review' },
  { value: 7, label: 'Highest rating' },
  { value: 8, label: 'Highest number of reviews ' },
  { value: 9, label: 'Highest number of solds ' }
]

// 所有房源类型排序集合
export const ORDER_MAP = {
  [houseSearchType.residential]: ORDER_HOUSE,
  [houseSearchType.rent]: ORDER_HOUSE,
  [houseSearchType.sold]: ORDER_SOLD,
  [houseSearchType.rural]: ORDER_HOUSE,
  [houseSearchType.commercialForSale]: ORDER_COMMERCIAL,
  [houseSearchType.commercialForLease]: ORDER_COMMERCIAL,
  [houseSearchType.business]: ORDER_BUSINESSES,
  [houseSearchType.newHomes]: ORDER_HOUSE
}

// 房源搜索需要单独提出来的filter alias
export const HOUSE_FILTER_ALIAS = {
  price: ['prn', 'prrn', 'prcsn', 'prcln', 'prrun', 'prbn'],
  bedroom: ['bern'],
  bathroom: ['barn'],
  carspaces: ['pkn'],
  propertyType: ['ptn', 'ptrn', 'ptrrn'],
  categories: ['ptcsn', 'ptcln', 'ptbn'],
  landarea: ['lacn', 'larn'],
  floorarea: ['facn'],
}

// 路由上使用的filter alias
// 这里的filter需要写到路由上去
export const ROUTER_FILTER_ALIAS = [
  ...HOUSE_FILTER_ALIAS.price,
  ...HOUSE_FILTER_ALIAS.bedroom,
  ...HOUSE_FILTER_ALIAS.bathroom,
  ...HOUSE_FILTER_ALIAS.carspaces,
  ...HOUSE_FILTER_ALIAS.propertyType,
  ...HOUSE_FILTER_ALIAS.categories,
  ...HOUSE_FILTER_ALIAS.landarea,
  ...HOUSE_FILTER_ALIAS.floorarea,
]

// 默认展开的类型
export const DEFAULT_SPREAD_ALIAS = [
  ...HOUSE_FILTER_ALIAS.price,
  ...HOUSE_FILTER_ALIAS.bedroom,
  ...HOUSE_FILTER_ALIAS.bathroom,
  // 这里不能直接用HOUSE_FILTER_ALIAS.categories，因为businesses的categories是ptbn，businesses默认不展开categories
  // ...HOUSE_FILTER_ALIAS.categories,
  'ptcsn', 'ptcln',
  // agent
  'alp', 'als', 'alsr', 'alsc', 'alsb',
  // rural
  'ptrrn', 'larn'
]

// 默认展开的类型
export const DEFAULT_SPREAD_ALIAS_HT287 = [
  ...HOUSE_FILTER_ALIAS.price,
  // ...HOUSE_FILTER_ALIAS.bedroom,
  // ...HOUSE_FILTER_ALIAS.bathroom,
  // 这里不能直接用HOUSE_FILTER_ALIAS.categories，因为businesses的categories是ptbn，businesses默认不展开categories
  // ...HOUSE_FILTER_ALIAS.categories,
  ...HOUSE_FILTER_ALIAS.propertyType,
  // Type of title
  'tt',
  // Selling method
  'sm',
  'ptcsn', 'ptcln',
  // agent
  'alp', 'als', 'alsr', 'alsc', 'alsb',
  // rural
  'ptrrn', 'larn'
]

// 房源列表页地图是否展示KEY
export const HOUSE_LIST_SHOW_MAP_KEY = 'show-map'

// 房源列表地图查看过的ids KEY
export const HOUSE_LIST_VIEWED_IDS_KEY = 'listing-viewed-ids'

// 最后一次estimate map搜索 KEY
export const LAST_ESTIMATE_MAP_SLUG_KEY = 'last-estimate-map-slug'

// builder property price range
export const BUILDER_PROPERTY_PRICE_RANGE = [
  { value: null, label: 'Any' },
  { value: 50000, label: '$50k' },
  { value: 100000, label: '$100k' },
  { value: 150000, label: '$150k' },
  { value: 200000, label: '$200k' },
  { value: 250000, label: '$250k' },
  { value: 300000, label: '$300k' },
  { value: 350000, label: '$350k' },
  { value: 400000, label: '$400k' },
  { value: 450000, label: '$450k' },
  { value: 500000, label: '$500k' },
  { value: 550000, label: '$550k' },
  { value: 600000, label: '$600k' },
  { value: 650000, label: '$650k' },
  { value: 700000, label: '$700k' },
  { value: 750000, label: '$750k' },
  { value: 800000, label: '$800k' },
  { value: 850000, label: '$850k' },
  { value: 900000, label: '$900k' },
  { value: 950000, label: '$950k' },
  { value: 1000000, label: '$1m' },
  { value: 1100000, label: '$1.1m' },
  { value: 1200000, label: '$1.2m' },
  { value: 1300000, label: '$1.3m' },
  { value: 1400000, label: '$1.4m' },
  { value: 1500000, label: '$1.5m' },
  { value: 1600000, label: '$1.6m' },
  { value: 1700000, label: '$1.7m' },
  { value: 1800000, label: '$1.8m' },
  { value: 1900000, label: '$1.9m' },
  { value: 2000000, label: '$2.0m' },
  { value: 2250000, label: '$2.25m' },
  { value: 2500000, label: '$2.5m' },
  { value: 2750000, label: '$2.75m' },
  { value: 3000000, label: '$3.0m' },
  { value: 3500000, label: '$3.5m' },
  { value: 4000000, label: '$4.0m' },
  { value: 5000000, label: '$5.0m' },
  { value: 6000000, label: '$6.0m' },
  { value: 7000000, label: '$7.0m' },
  { value: 8000000, label: '$8.0m' },
  { value: 9000000, label: '$9.0m' },
  { value: 10000000, label: '$10.0m' }
]

// 首页tabs
export const RESIDENTIAL_TABS = [
  { label: 'Buy', value: 1, showFilter: true },
  { label: 'Estimate', value: 2, showFilter: false },
  { label: 'Rental', value: 3, showFilter: true },
]

// segment选项
export const segments = [
  { label: 'First home buyer', value: 'fhb' },
  { label: 'Buyer', value: 'buyer' },
  { label: 'Homeowner', value: 'homeowner' },
  { label: 'Seller', value: 'seller' },
  { label: 'Keeping up to date', value: 'keeping_up' },
  { label: 'Other', value: 'other' }
]